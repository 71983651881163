import $ from 'jquery';
import BigImg from '../BigImg.vue';
import { getAnyList, saveAny2, saveCache, getCache } from '../../api/list';
import { Notify } from 'vant';
import { router } from '../../router';
import 'vant/es/notify/style';
export default {
  setup() {
    const afterRead = file => {
      // 此时可以自行将文件上传至服务器
      // this.fileList.add(file);
      const maxSize = file.type === 'image/jpeg' ? 500 * 1024 : 1000 * 1024;
      return file.size >= maxSize;
    };

    return {
      afterRead
    };
  },

  name: 'Any',

  data() {
    return {
      selectedDate1: '',
      // 用于存储日期的数据属性
      selectedDate2: '',
      searchKey: '',
      fireList: [],
      content: '',
      noteMail: false,
      ssd: [],
      account: {},
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
          ['blockquote', 'code-block'], // 引用  代码块
          [{
            header: 1
          }, {
            header: 2
          }], // 1、2 级标题
          [{
            list: 'ordered'
          }], // 有序、无序列表
          [{
            script: 'sub'
          }, {
            script: 'super'
          }], // 上标/下标
          [{
            indent: '-1'
          }, {
            indent: '+1'
          }], // 缩进
          [{
            direction: 'rtl'
          }], // 文本方向
          [{
            color: []
          }, {
            background: []
          }], // {  color: [] }, 字体颜色、字体背景颜色
          [{
            font: []
          }], // 字体种类
          [{
            align: []
          }], // 对齐方式
          ['clean'] // 清除文本格式
          ] // 工具菜单栏配置

        },
        placeholder: '矮泥鸭~\n\n\n',
        // 提示
        readyOnly: false,
        // 是否只读
        theme: 'snow',
        // 主题 snow/bubble
        syntax: true,
        // 语法检测
        edit: ''
      },
      fileList: [],
      // 图片预览地址
      totalPage: '',
      pageNo: 1,
      pageSize: 5,
      so: 1,
      sizeback: 4,
      // 限制上传数量
      showImg: false,
      imgSrc: '',
      tipMsg: '',
      searchStatus: false
    };
  },

  components: {
    'big-img': BigImg
  },

  created() {
    console.log('init');
    this.getListData();
    window.addEventListener('scroll', this.onScroll); //加载缓存数据

    this.getCacheData();
    console.log('加载缓存数据');
    this.startInterval();
  },

  methods: {
    selectDate1() {
      console.log("------111" + this.selectedDate1);
    },

    selectDate2() {
      console.log("-----222-" + this.selectedDate2);
    },

    getCacheData() {
      getCache().then(res => {
        console.log('加载缓存数据=', res.data);

        if (res.code == 200) {
          this.content = res.data;
        }
      });
    },

    gotomilk() {
      router.push({
        path: '/milk'
      });
    },

    gotovalid() {
      router.push({
        path: '/valid'
      });
    },

    clickImg(e) {
      this.showImg = true;
      this.imgSrc = e.currentTarget.src;
    },

    viewImg() {
      this.showImg = false;
    },

    getListData() {
      if (this.searchKey != '' && this.searchKey != undefined) {
        console.log('搜索=' + this.searchKey);
        this.searchStatus = true;
        this.pageNo = 1;
      } else {
        this.searchStatus = false;
      }

      getAnyList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        searchKey: this.searchKey,
        st: this.selectedDate1,
        et: this.selectedDate2
      }).then(res => {
        var data = res.data;
        console.log(data, this.searchKey); //显示第一页数据时清空页面

        if (this.pageNo == 1) {
          this.ssd = [];
        }

        for (var i = 0; i < data.anyList.length; i++) {
          this.ssd.push(data.anyList[i]);
        }

        this.account = data.memberVO;
        this.totalPage = data.pageTotal;
        console.log(this.account);
      });
    },

    release() {
      console.log('发布', this.noteMail);
      console.log(this.fileList);
      var picList = new Array();
      this.fileList.forEach(item => {
        picList.push(item.content); // 将每一个文件图片都加进formdata
      });
      var json = {
        content: this.content,
        noteMail: this.noteMail,
        picList: picList
      };
      saveAny2(json).then(res => {
        console.log(res); // 成功通知

        Notify({
          type: 'success',
          message: '发布成功'
        });
        this.ssd = [];
        this.getListData();
        this.content = '';
      }); // --------------------------------
    },

    handlerCancel() {
      console.log(event.target.checked);
      this.noteMail = event.target.checked;
    },

    handleupload(param) {
      console.log('添加图片');
      this.fileList.push(param.file); // 一般情况下是在这里创建FormData对象，但我们需要上传多个文件，为避免发送多次请求，因此在这里只进行文件的获取，param可以拿到文件上传的所有信息
    },

    goToAccount() {
      this.$router.push('/member');
    },

    // 滚动触底加载
    onScroll() {
      if ($(window).height() + $(window).scrollTop() + 60 > $(document).height()) {
        this.so++;
        console.log(this.so);

        if (this.totalPage > this.pageNo) {
          if (this.so % 2 === 0) {
            this.pageNo++;
            this.getListData();
          }
        } else {
          console.log('最后一页了');
          this.tipMsg = '---没有更多了---';
        }
      }
    },

    callBackendAPI() {
      var json = {
        content: this.content,
        noteMail: this.noteMail
      };
      saveCache(json).then(res => {
        console.log('保存缓存数据', res.data);
      });
    },

    // 启动定时器的方法
    startInterval() {
      this.intervalId = setInterval(this.callBackendAPI, 5000);
    }

  }
};